import React from 'react';
import './menu-icon.css'

class HamburgerIcon extends React.Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  }

  render() {
    const { variant, open } = this.props;
    let divProps = {
      onClick: this.handleClick,
      className: open ? "open" : ""
    };

    const variants = {
      "1": (
        <div id="nav-icon1" {...divProps}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      ),
      "2": (
        <div id="nav-icon2" {...divProps}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      ),
      "3": (
        <div id="nav-icon3" {...divProps}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      ),
      "4": (
        <div id="nav-icon4" {...divProps}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )
    };

    return (
      <div>
        {variants[variant]}
      </div>
    );
  }
}

export default HamburgerIcon;