import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import HamburgerIcon from '../nav/menu-icon';

const styles = {
  root: {
    position: "absolute",
    marginTop: "10px",
    marginLeft: "10px"
  },
  menu: {
    marginTop: "5px",
    opacity: 0.8
  }
};

class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    }
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null
    });
  }

  renderMenu() {
    return (
      <div style={styles.root}>

        <HamburgerIcon variant="3" onClick={(event) => this.handleClick(event)} open={Boolean(this.state.anchorEl)} />

        <Menu style={styles.menu}
              getContentAnchorEl={null}
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={() => this.handleClose()}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }} transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}>
          <MenuItem onClick={() => this.handleClose()}>
            <ListItem button key="Home" component={Link} to='/'>
              <ListItemText primary="Home" />
            </ListItem>
          </MenuItem>
          <MenuItem onClick={() => this.handleClose()}>
            <ListItem button key="Our App" onClick={this.props.signup}>
              <ListItemText primary="Our App" />
            </ListItem>
          </MenuItem>
          <MenuItem onClick={() => this.handleClose()}>
            <ListItem button key="About Us" component={Link} to='/aboutus'>
              <ListItemText primary="About Us" />
            </ListItem>
          </MenuItem>
          <MenuItem onClick={() => this.handleClose()}>
            <ListItem button key="Web3" component={Link} to='/Web3'>
              <ListItemText primary="Web3" />
            </ListItem>
          </MenuItem>
          <MenuItem onClick={() => this.handleClose()}>
            <ListItem button key="Team" component={Link} to='/team'>
              <ListItemText primary="Team" />
            </ListItem>
          </MenuItem>
        </Menu>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderMenu()}
      </div>
    );
  }
}

Nav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Nav));