import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SignupPopup from "./components/signupform";
import Nav from "./components/nav";
import Home from "./components/home";
import AboutUs from "./components/aboutus";
import Team from "./components/team";
import Web3 from "./components/web3";
import TermsOfUse from "./components/terms";

class Router extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      signupOpen: false
    }
  }

  componentWillMount() {
    window.setTimeout(() => this.handleSignupOpen(), 2000);
  }

  handleSignupOpen() {
    this.setState({
      signupOpen: true
    });
  }

  handleSignupClose() {
    this.setState({
      signupOpen: false
    });
  }

  render() {
    return (
      <BrowserRouter>
        <Nav signup={() => this.handleSignupOpen()} closeSignup={() => this.handleSignupClose()}/>
        <SignupPopup open={this.state.signupOpen} onClose={() => this.handleSignupClose()}/>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/Web3" component={Web3} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/terms" component={TermsOfUse} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;