import React from "react";
import ParallaxImage from "./parallax-image";
import Lion from "../../images/lion.jpg";

const styles = {
  root: {
    textAlign: "center"
  },
  footer: {
    color: "white",
    fontFamily: "Raleway",
    fontSize: "4vw",
    paddingBottom: "8%"
  }
};

class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      posts: [],
      scrolling: false
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillMount() {
    window.Api.get("/rest/public/feed/top?size=10").then(resp => {
      // console.log(resp.data);
      this.setState({
        posts: resp.data.content,
        isLoading: false
      });
    }).catch(error => {
      console.error(error);
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e){
    if(this.timer !== null) {
      clearTimeout(this.timer);
      this.setState({scrolling: true});
    }

    this.timer = setTimeout(() => {
      this.setState({scrolling: false});
    }, 300);
  }

  renderImages() {
    return this.state.posts.map((post, idx) =>
      <ParallaxImage key={idx}
                     img={post.mediaUrl}
                     scrolling={this.state.scrolling}
                     name={post.postName}
                     artist={post.artist.username}
                     score={post.score.toFixed(2)}
      />
    );
  }

  renderFooterText() {
    if (!this.state.scrolling) {
      return null;
    }
    return <div style={styles.footer}>Sign into the Chromatic Network to see more…</div>
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }
    return (
    <div style={styles.root}>
      <ParallaxImage img={Lion} showLogo/>
      {this.renderImages()}
      {this.renderFooterText()}
    </div>
    )
  }
}

export default Home;
