export const styles = {
  root: {
    fontFamily: "Raleway",
    color: "white",
    textAlign: "center",
    paddingTop: "15vh",
    paddingBottom: "15vh"
  },
  title: {
    fontSize: "4vh",
    paddingTop: "5vh",
    paddingBottom: "20px",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  subtitle: {
    fontSize: "2vh",
    marginBottom: "20px",
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  video: {
    maxWidth: "80%",
    height: "auto",
    marginBottom: "45px"
  },
  contactUs: {
    fontSize: "3vh",
    textAlign: "center"
  },
  contactUsSub: {
    fontSize: "1.8vh",
    textDecoration: "none"
  }
};