import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import _ from "lodash";
import { styles } from "./form.styles";
import { withRouter, Link } from "react-router-dom";
import GoogleLogo from "../../images/googlelogo.png";
import AppleLogo from "../../images/applelogo.png";

class SignupForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      email: "",
      error: "",
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      error: ""
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.firstname.trim() === "" ||
        this.state.lastname.trim() === "" ||
        this.state.username.trim() === "" ||
        this.state.password.trim() === "") {
      this.setState({
        error: "Please fill in all required fields (*)"
      });
      return;
    }

    let data = _.omit(this.state, ['error']);
    data['source'] = 'WEB';
    data['device'] = navigator.platform;
    window.Api.post("/rest/register/account", data).then(resp => {
      this.props.onSubmit();
    }).catch(error => {
      console.log(error);
      this.setState({
        error: error.data.message
      })
    });
  }

  handleCancel() {
    this.props.onCancel();
  }

  render() {
    let formControlProps = {
      margin: "dense",
      fullWidth: true
    };
    if (this.props.horizontal) {
      formControlProps.fullWidth = false;
    }

    return (
      <div>
        <div style={styles.cancelButton} onClick={() => this.handleCancel()}>
          <CloseIcon style={styles.cancelButtonIcon} />
        </div>
        <div className="header" style={styles.joinapp}>Join our app!</div>
        <form noValidate autoComplete="off">
          <div className="subheader"></div>
          {!_.isNil(this.state.error) && <div className="error">{this.state.error}</div>}

          <a href="https://play.google.com/store/apps/details?id=com.alchephi.chromatic" target="_blank" rel="noreferrer">
              <img style={styles.storelogoGoogle} alt="Google Store" src={GoogleLogo}/>
            </a>
          <a href="https://apps.apple.com/us/app/chromatic-3/id1592797457" target="_blank" rel="noreferrer">
              <img style={styles.storelogoApple} alt="Apple Store" src={AppleLogo}/>
            </a>
          {/* <TextField {...formControlProps}
            label="Last Name *"
            onChange={this.handleInputChange}
            name="lastname"
            value={this.state.lastname}
          /> */}
          {/* <button type="button" className="submitButton" onClick={this.handleSubmit}>
            Placeholder
          </button> */}
          <div className="footer">
            By signing up you agree to the <Link style={{color: "white"}} to="/terms" onClick={() => this.handleCancel()}>Terms of Use</Link> policy
          </div>
        </form>
      </div>
    )
  }
}

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default withRouter(SignupForm);