import React from 'react';
import Flipcard from '@kennethormandy/react-flipcard';
import Modal from 'react-modal';
import SignupForm from "./form";
import AppStoreLinks from "./appstore-links";

import './index.css';
import './flipcard.css';

Modal.setAppElement('#root');

class SignupPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flipped: false,
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      email: "",
      error: "",
    };
  }

  flip() {
    this.setState({
      flipped: !this.state.flipped
    })
  }

  handleClose() {
    this.setState({
      flipped: false
    });
    this.props.onClose();
  }

  render() {
    let isMobile = window.innerWidth < 800 && window.innerHeight < 800;
    let isLandscape = window.innerHeight < window.innerWidth;

    return (
      <div>
        <Modal
          isOpen={this.props.open}
          onRequestClose={this.closeModal}
          className="Modal"
          overlayClassName="Overlay"
          contentLabel="Sign Up"
        >
          <div>
            <Flipcard flipped={this.state.flipped}>
              <div>
                <SignupForm onCancel={() => this.handleClose()} onSubmit={() => this.flip()} horizontal={isMobile && isLandscape}/>
              </div>
              <div style={{width: "100%"}}>
                <AppStoreLinks onCancel={() => this.handleClose()} />
              </div>
            </Flipcard>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SignupPopup