export const styles = {
  cancelButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
    cursor: "pointer"
  },
  cancelButtonIcon: {
    fontSize: "2em"
  },
  storelogoGoogle: {
    height: "90px",
    padding: '20px',
  },

  storelogoApple: {
    height: "100px",
    padding: '20px',
  },

  joinapp: {
    fontSize: '30px',
  }


};