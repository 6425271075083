import React from 'react';
import { styles } from './index.style';

class Web3 extends React.Component {
  render() {
    return (
      <div style={styles.root}>
        <h1 style={styles.title}>Web3 coming soon...</h1>
        <p style={styles.subtitle}>
          Chromatic will return ownership of media accounts back to the users, in the process enabling users to be rewarded with Chrome Coin for their posts, rankings and reviews.
        </p>
      </div>
    )
  }
}

export default Web3;