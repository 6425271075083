import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import YesLogo from "../../images/yes logo small@3x.png";

const styles = {
  titleArtist: {
    fontFamily: "Raleway",
    fontWeight: 100,
    padding: "40px",
    position: "relative",
    textAlign: "left",
    top: "10%"
  },
  logoStyle: {
    fontFamily: "Housegrind",
    fontSize: "18vw",
    color: "#f2c076",
    padding: 20,
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  },
  title: {
    fontSize: "5vmax",
    color: "white",
    top: "1%"
  },
  artist: {
    fontSize: "0.75em",
    fontStyle: "italic",
    marginTop: "-10px"
  },
  scoreWrapper: {
    fontFamily: "Raleway",
    fontSize: "6vmax",
    color: "white",
    padding: 20,
    position: "absolute",
    bottom: "1%",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  scoreLogoWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  scoreLogo: {
    width: "6vw",
    marginBottom: "-2vw"
  },
  scoreLabel: {
    fontSize: "1.5vmax",
    textAlign: "left"
  },
  scoreValue: {
    fontSize: "4vmax",
    paddingTop: "5vw",
  }
};

class ParallaxImage extends React.Component {

  renderTitleArtist() {
    const {scrolling, name, artist} = this.props;
    if (!scrolling) {
      return null;
    }
    return (
      <div style={styles.titleArtist}>
        <div style={styles.title}>{name}<div style={styles.artist}>@{artist}</div></div>
      </div>
    );
  }

  renderScore() {
    const {scrolling, score} = this.props;
    if (!scrolling) {
      return null;
    }
    return (
      <div style={styles.scoreWrapper}>
        <div style={styles.scoreLogoWrapper}>
          <div><img src={YesLogo} style={styles.scoreLogo} alt="cscore"/></div>
          <div style={styles.scoreLabel}>SCORE</div>
        </div>
        <div style={styles.scoreValue}>{score}</div>
      </div>
    );
  }

  render() {
    const { img } = this.props;
    let layers = [
        {
          image: img,
          amount: .4,
          expanded: false
        }
      ];

    if (this.props.showLogo) {
      layers.push({
        amount: .4,
        expanded: false,
        children: <div style={styles.logoStyle}>Chromatic</div>
      })
    }

    return (
      <ParallaxBanner
        layers={layers}
        style={{ height: '85vh', top: '-10vh'}} >
        {this.props.children}
        {this.renderTitleArtist()}
        {this.renderScore()}
      </ParallaxBanner>
    );
  }
}

export default ParallaxImage;

/*
,
        {

        }
 */