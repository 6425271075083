import axios from "axios";
import Config from "./config";

axios.defaults.baseURL = Config.apiBaseUrl;
axios.defaults.headers.common["Content-Type"] = "application/json";

const Api = {
  delete(url, data, config = {}) {
    config.method = "delete";
    config.url = url;
    config.data = data;
    return this.request(config);
  },

  get(url, config = {}) {
    config.method = "get";
    config.url = url;
    return this.request(config);
  },

  patch(url, data, config = {}) {
    config.method = "patch";
    config.url = url;
    config.data = data;
    return this.request(config);
  },

  post(url, data, config = {}) {
    config.method = "post";
    config.url = url;
    config.data = data;
    return this.request(config);
  },

  put(url, data, config = {}) {
    config.method = "put";
    config.url = url;
    config.data = data;
    return this.request(config);
  },

  request(config) {
    return new Promise((resolve, reject) => {
      return axios(config).then((response) => {
        return(resolve(response));
      }).catch((error) => {
        return(reject(error.response));
      });
    });
  }
};

export default Api;
