export const styles = {
  root: {
    fontFamily: "Raleway",
    textAlign: "center",
    color: "white",
    fontSize: "1.3vmax"
  },
  cancelButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
    cursor: "pointer"
  }
};