export const styles = {
  root: {
    fontFamily: "Raleway",
    color: "white",
    textAlign: "center",
    paddingTop: "15vh",
    paddingBottom: "15vh"
  },
  title: {
    fontSize: "4vh",
    paddingTop: "5vh",
    paddingBottom: "20px",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  subtitle: {
    fontSize: "2vh",
    marginBottom: "20px",
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  teammate_name: {
    fontSize: "2vh",
    // marginBottom: "20px",
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  teammate_block:{
    marginBottom: "50px"
  },
  teammates: {
    marginBottom: "px"
  },
  teammate_position: {
    fontSize: "1.5vh",
    fontStyle: 'italic',
    paddingLeft: "20px",
    paddingRight: "20px",
    marginBottom: "15px"
  },
  teammate_desc: {
    fontSize: "1.5vh",
    fontStyle: 'normal',
    paddingLeft: "20px",
    paddingRight: "20px",
    margin: 'auto',
    marginBottom: '40px',
    maxWidth: "500px",
    textAlign: "center",
    
  },
  teammate_social_icons: {
    height: "2.5vh",
    width: "2.5vh",
  },
  video: {
    maxWidth: "80%",
    height: "auto",
    marginBottom: "45px"
  },
  teammate_pic: {
    maxWidth: "200px",
    height: "200px",

  },
  contactUs: {
    fontSize: "3vh",
    textAlign: "center"
  },
  contactUsSub: {
    fontSize: "1.8vh",
    textDecoration: "none"
  }
};