import React from 'react';
import { SocialIcon } from 'react-social-icons';
import { styles } from './index.style';
import Deva from "../../images/devafinal.png";
import Sawyer from "../../images/sawyerfinal.png";
import Brian from "../../images/brianfinal.png";
import Casey from "../../images/caseyfinal.png";
import Ryan from "../../images/ryanfinal.png"

class Team extends React.Component {

  render() {
    return (
      <div style={styles.root}>
        <h1 style={styles.title}>Chromatic Team</h1>
        <div style={styles.teammates}>
          <div style={styles.teammate_block}>
            <a style={styles.teammate_pic} href="https://www.linkedin.com/in/devafinger/">
              <img style={styles.teammate_pic} alt="deva finger" src={Deva}/>
            </a>
            <p style={styles.teammate_name}>
                  Deva Finger  <SocialIcon style={styles.teammate_social_icons} url="http://linkedin.com/in/devafinger" />
            </p>
            <p style={styles.teammate_position} >
              Founder and CEO
            </p>
            <p style={styles.teammate_desc} >
            After a decade career in investment banking Deva founded a private equity fund and brand incubator focused on niche consumer brands.  Deva has since started, acquired, operated and managed multiple high-end brand businesses. Including leading multiple successful company exits Deva graduated from the Stanford GSB and Brandeis University with honors in Economics.
            </p>
          </div>
          <div>
            <a href="https://www.linkedin.com/in/sawyerwaugh/">
              <img style={styles.teammate_pic} alt="sawyer waugh" src={Sawyer}/>
            </a>
            <p style={styles.teammate_name}>
                  Sawyer Waugh <SocialIcon style={styles.teammate_social_icons} url="http://linkedin.com/in/sawyerwaugh" />

              </p>
            <p style={styles.teammate_position} >
              Data Science
            </p>
            <p style={styles.teammate_desc} >
            Sawyer has 7yrs experience working in advanced data analytics.  He has worked as a Software Engineer at MedTrak, an Engineer at Justifi and in Data Analytics at Luxury Brand Partners, along side Deva Finger.  Sawyer graduated from the University of Pennsylvania with a degree in Engineering (BSE), Computer and Information Science. 
            </p>
          </div>
          <div>
            <a href="https://www.linkedin.com/in/suborbital/">
              <img style={styles.teammate_pic} alt="ryan mclean" src={Ryan}/>
            </a>
            <p style={styles.teammate_name}>
                  Ryan Mclean <SocialIcon style={styles.teammate_social_icons} url="http://linkedin.com/in/suborbital" />

              </p>
            <p style={styles.teammate_position} >
              Data Analytics
            </p>
            <p style={styles.teammate_desc} >
            Ryan is a C-level executive with two decades of experience in data science and astrophysics.  Ryan was the COO of Razor USA for 7yrs after he attended Stanford Business School.  Prior to Stanford Ryan was a Principal Research Scientist at Caltech where he developed software for NASA space explorations.
            </p>
          </div>
          <div>
            <a href="https://www.linkedin.com/in/caseytewey/">
              <img style={styles.teammate_pic} alt="casey tewey" src={Casey}/>
            </a>
            <p style={styles.teammate_name}>
                  Casey Tewey <SocialIcon style={styles.teammate_social_icons} url="http://linkedin.com/in/caseytewey" />
              </p>
            <p style={styles.teammate_position} >
              Blockchain Lead
            </p>
            <p style={styles.teammate_desc} >
            Casey is a double major in computer science and finance at MIT.  He is a member of the advisory board for the Martin Trust Center for Entrepreneurship and a pitcher on the MIT Varsity Baseball team.  He worked at Evaluative Criteria Inc and spent the last two years researching blockchain as it relates to the best practices in building resilient systems, by way of code and overall design features. 
            </p>
          </div>
          <div>
            <a href="https://www.linkedin.com/in/brian-rapanan/">
              <img style={styles.teammate_pic} alt="brian rapanan" src={Brian}/>
            </a>
            <p style={styles.teammate_name}>
                  Brian Rapanan <SocialIcon style={styles.teammate_social_icons} url="http://linkedin.com/in/brian-rapanan" />

              </p>
            <p style={styles.teammate_position} >
              Data Analyst
          </p>
          <p style={styles.teammate_desc} >
          Brian is studying computer science and mathematics at MIT with a focus on Software Performance Engineering and Advanced Machine Learning.  He is also a pitcher on the MIT Varsity Baseball team. 
            </p>
          </div>

        </div>

        <div style={styles.contactUs}>
          <u>JOIN THE TEAM</u>
          <div style={styles.contactUsSub}>
            Interested in building with us? Drop us a note at admin@chromaticsystems.com!
          </div>
        </div>
      </div>
    )
  }
}

export default Team;
