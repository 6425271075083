const dev = {
  apiBaseUrl: "http://localhost:8080/"
};

const test = {
  apiBaseUrl: "https://api.chromaticsystems.com/"
};

const prod = {
  apiBaseUrl: "https://api.chromaticsystems.com/"
};

let config = null;
if (process.env.REACT_APP_STAGE.trim() === 'production') {
  config = prod;
} else if (process.env.REACT_APP_STAGE.trim() === 'test') {
  config = test;
} else {
  config = dev;
}

export default config;
