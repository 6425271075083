import React from "react";
import Router from "./router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ParallaxProvider } from 'react-scroll-parallax';

class App extends React.Component {
  render() {
    // customize there here!
    const theme = createMuiTheme({
      typography: {
        useNextVariants: true,
        fontFamily: [
          'Lucida Grande',
          'sans-serif'
        ].join(',')
      },
      palette: {
        type: 'light',
        background: {
          default: 'black'
        }
      },
      overrides: {
        MuiFormLabel: {
          root: {
            color: "white",
            "&$focused": {
              color: "white"
            }
          }
        }
      }
    });

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ParallaxProvider>
          <Router />
        </ParallaxProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
