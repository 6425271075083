import React from 'react';
import IOSImage from "../../images/ios-badge.png";
import GoogleImage from "../../images/google-play-badge.png";
import CloseIcon from "@material-ui/icons/Close";
import { styles } from "./appstore-links.style";

class AppStoreLinks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showGooglePlayMessage: false
    }
  }
  handleCancel() {
    this.props.onCancel();
  }

  openLink(url) {
    window.open(url, "_blank");
    this.handleCancel();
  }

  renderGooglePlayMessage() {
    if (!this.state.showGooglePlayMessage) {
      return null;
    }
    return <div><br/>Hang in there! Chromatic is coming soon to the Google Play store. We will contact you once the new android version is available.</div>
  }

  render() {
    return (
      <div style={styles.root}>
        <div style={styles.cancelButton} onClick={() => this.handleCancel()}>
          <CloseIcon />
        </div>
        <div className="header">Download</div>
        <div>and login to the app with your new credentials</div>
        <br/>
        <div>
          <a href="##" onClick={() => this.openLink("https://apps.apple.com/us/app/chromatic-3/id1592797457")}><img height={40} src={IOSImage} alt="IOS AppStore"/></a>&nbsp;
          <a href="##" onClick={() => this.openLink("https://play.google.com/store/apps/details?id=com.alchephi.chromatic")}><img height={40} src={GoogleImage} alt="Google Play"/></a>&nbsp;
          {this.renderGooglePlayMessage()}
        </div>
      </div>
    )
  }
}

export default AppStoreLinks;