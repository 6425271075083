import React from 'react';
import { styles } from './index.style';

class AboutUs extends React.Component {
  render() {
    return (
      <div style={styles.root}>
        <h1 style={styles.title}>The Chromatic Way</h1>
        <p style={styles.subtitle}>
        Chromatic provides validation of online content through qualified crowdsourced ratings on the blockchain.  The news, products, posts and tweets that we consume online are full of bias, bots and corrupted content.  Chromatic’s machine learning algorithm identifies, elevates and rewards high quality material while eliminating misinformation and inferior content.

The information we consume online needs validation and democratization.  Ratings, if accurate and unbiased, provide factual and reliable 3rd party qualification of info, products and content.  But ratings are seldom free of bias and corruption and there are not enough professional critics available to effectively assess all the content we consume online.  Nobody can accurately rate everything, however everybody can!

Chromatic crowdsources ratings on any online content while its patented machine learning AI assesses raters’ accuracy and bias in ranking and weights their votes accordingly to produce high validity ratings.  Chromatic rewards judicious, accurate, unbiased voting and quality content creation on the blockchain, resulting in broadly available highly qualified decentralized information, while furthering creativity and innovation.
        </p>

        
      </div>
    )
  }
}

export default AboutUs;